import { graphql } from "gatsby";
import React from "react";
import LayoutSub from "../components/layout-sub";
import SEO from "../components/seo";
import CtaTjansterPage from "../components/cta-tjanster-page";
import WidgetContactEmail from "../components/widget-contact-email";
import WidgetContactPhone from "../components/widget-contact-phone";

export default ({data}) => (
  <LayoutSub title="Tjänster">
    <SEO
      title="Tjänster"
      description={data.markdownRemark.frontmatter.seo.description}
      keywords={data.markdownRemark.frontmatter.seo.keywords.toString().split(',')}
    />
    
    <article id="main-article" className="container flex flex-wrap items-start lg:flex-no-wrap mx-auto p-4 md:p-8 xl:p-16">
      <div role="main" className=" w-full lg:w-2/3 lg:pr-16 xl:pr-32">
        <CtaTjansterPage />
      </div>

      <aside id="sidebar" className="w-full bg-grey-lightest px-8 pt-8 lg:pt-16 lg:-mt-16 xl:p-16 xxl:pt-16 xxl:p-8 lg:w-1/3">
        <section className="bg-white mb-8 p-6">
          <h5 className="text-sm mb-2 hyphens leading-normal">Vi har resurserna som krävs</h5>
          <p className="mb-4 text-xs">Vi åtar oss de flesta uppdarag som ställer höga krav på precision och kvalitet. Med lång erfarenhet är vi er säkra partner inom stålkonstruktion och svetsning.</p>
        </section>

        <section className="xxl:flex">
          <WidgetContactEmail />
          <WidgetContactPhone />
        </section>
      </aside>
    </article>
  </LayoutSub>
)

export const query = graphql`
{
  fileName: file(relativePath: { eq: "sub-page-half.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 390) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  markdownRemark(frontmatter: {name: {eq: "Om oss"}}) {
    frontmatter {
      title
      name
      slug
      seo {
        description
        keywords
        title
      }
      text_header {
        heading
      }
    }
    html
  }
}
`