import { StaticQuery, graphql, Link } from "gatsby";
import React from "react";

const CtaTjansterPage = () => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: {name: {eq: "Tjanster"}}) {
          frontmatter {
            cta_tjanster {
              heading
              blocks {
                heading
                body
                url
              }
            }
          }
        }
        tjanster: allMarkdownRemark(filter: {frontmatter: {type: {eq: "service"}}}, sort: {fields: frontmatter___order}) {
          edges {
            node {
              id
              frontmatter {
                name
                slug
                service_excerpt
              }
            }
          }
        }
      }
    `}

    render={data => (
      <section className="relative">
        <div className="container mx-auto overflow-y-visible">
          <div className="relative mb-8 md:mb-0">
            {data.tjanster.edges.map((edge, i) => (
              <section className="py-2 relative w-full md:pb-8" key={i}>
                <div className="">
                  <h2 className="font-bold mt-0 mb-0 md:mb-4" style={{ hyphens: 'auto' }}>{edge.node.frontmatter.name}</h2>
                  <p className="hidden text-sm md:block" style={{ hyphens: 'auto' }}>{edge.node.frontmatter.service_excerpt}</p>
                  <Link
                    to={edge.node.frontmatter.slug}
                    className="no-underline text-orange link transition hover:text-orange-dark"
                  >
                    Läs mer
                  </Link>
                </div>
              </section>
            ))}
          </div>
        </div>
      </section>
    )}
  />
)

export default CtaTjansterPage;